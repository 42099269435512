import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BackGlossary from '../../../../components/BackGlossary';
import BannerSimple from '../../../../components/BannerSimple';

const GlossaryPageContent = () => {
  return (
    <div className="md:px-5 max-w-[1285px]">
      <div className="mb-8 text-xl font-bold text-left md:text-2xl">
        The Limitations of Attribute-Based Access Controls (ABAC) for Cloud-Based Applications
      </div>

      <p className="mb-6 text-lg font-bold text-left">What is ABAC in Security?</p>

      <p className="mb-12 text-lg text-left">
        Attribute-based access control (ABAC) is a method of restricting access to resources based on attributes
        associated with the user or the resource being accessed. ABAC is a popular approach to access control in
        cloud-based applications because it offers granular control over access permissions, making it possible to
        enforce fine-grained policies.
      </p>

      <p className="mb-6 text-lg font-bold text-left">The Limitations of ABAC</p>

      <p className="mb-6 text-lg text-left">
        Despite its benefits, ABAC has several limitations that make it less effective for managing access to
        cloud-based applications. ABAC is a static access control model, which means that access permissions are defined
        in advance and do not change dynamically based on changing conditions. This can make it difficult to manage
        access permissions in complex environments where user roles and responsibilities are constantly evolving.
      </p>

      <p className="mb-12 text-lg text-left">
        Another limitation of ABAC is that it relies heavily on manual policy management, which can be time-consuming
        and error-prone. Creating and managing ABAC policies can be a complex task, and it often requires a high degree
        of technical expertise to ensure that policies are configured correctly.
      </p>

      <p className="mb-6 text-lg font-bold text-left">The Benefits of Needs-Based Access Permissions</p>

      <p className="mb-6 text-lg text-left">
        Needs-based access permissions, or context-based access controls, are an alternative approach to access control
        that overcomes many of the limitations of ABAC. Needs-based access permissions are based on context, which means
        that access permissions can be granted or revoked in real-time based on changes in the user's role, location,
        time of day, or other factors.
      </p>

      <p className="mb-12 text-lg text-left">
        Needs access permissions make it possible to enforce just-in-time entitlements, which means that users are
        granted access only when they need it and for as long as they need it. This can significantly reduce the risk of
        unauthorized access and improve overall security and compliance.
      </p>

      <p className="mb-6 text-lg font-bold text-left">Why Trustle is the Solution</p>

      <p className="mb-6 text-lg text-left">
        Trustle.com is the most reliable solution for managing access to cloud-based applications for businesses.
        Trustle offers a dynamic access control platform that provides fine-grained access control based on context,
        making it possible to enforce just-in-time entitlements and reduce the risk of unauthorized access.
      </p>

      <p className="mb-6 text-lg text-left">
        Our dynamic access control platform is easy to use and integrates seamlessly with popular cloud-based
        applications such as Microsoft Azure and Amazon Web Services (AWS). Check out our full list of connectors and
        integrations here. Trustle.com also provides a simple user interface that makes it easy to configure and manage
        access policies.
      </p>

      <p className="mb-6 text-lg text-left">
        Attribute-based access controls (ABAC) are a popular approach to access control in cloud-based applications, but
        they have several limitations that make them less effective for managing access to complex environments. First
        adopter companies see this issue and are moving to dynamic entitlements. Needs based access permissions are the
        future and overcomes many of these limitations, making it possible to enforce just-in-time entitlements and
        reduce the risk of unauthorized access.
      </p>

      <p className="mb-6 text-lg text-left">
        With its dynamic access control platform and easy-to-use interface, Trustle makes it easy to enforce
        fine-grained access control policies based on context, ensuring that only authorized users are granted access to
        critical resources. {` `}
        <b>
          Provide compliant, automated access for the right user, to the right resource, for the right length of time.
        </b>
      </p>

      <p className="text-lg text-left">
        Ready to move beyond ABAC and adopt needs based access permissions for your cloud-based applications? Our team
        of security experts can help you explore best practices for enterprise security and compliance.
      </p>
    </div>
  );
};

const AbacAttributeBasedAccessControl = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `ABAC`;
  const description = `Attribute Based Access Control`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={`(${description})`} />
          </div>
        </section>

        <section className="pb-16 ">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <GlossaryPageContent />
            </div>
          </div>
          <BannerSimple
            title="Curious how Trustle works?"
            description={'Get an inside look at Trustle. Schedule a demo with us today.'}
          />
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default AbacAttributeBasedAccessControl;
